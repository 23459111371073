import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import {
  Page,
  Container,
  ProjectHeader,
  PostFooter,
  SEO,
  SEOProps,
} from '~/components'
import IndexLayout from '~/layouts'

interface ProjectTemplateProps {
  data: {
    mdx: {
      body: string
      slug: string
      frontmatter: {
        title: string
        titleHtml: string
        thumbnail: string
        overview: string
        role: string
        tags: string[]
        liveUrl: string
        hidden: boolean
        cover?: {
          publicURL: string
        }
      }
    }
  }
  pageContext: {
    previous: {
      fields: {
        slug: string
      }
      frontmatter: {
        title: string
      }
    }
    next: {
      fields: {
        slug: string
      }
      frontmatter: {
        title: string
      }
    }
  }
}

const ProjectTemplate: React.FC<ProjectTemplateProps> = ({
  data,
  pageContext,
}) => {
  const {
    title,
    titleHtml,
    overview,
    tags,
    role,
    liveUrl,
    cover,
    hidden,
  } = data.mdx.frontmatter
  const { body, slug } = data.mdx
  const { previous, next } = pageContext

  const seo: SEOProps = {
    title: title,
    path: `/${slug}`,
    article: true,
    description: overview || title,
    image: cover?.publicURL || undefined,
    keywords: tags.length > 0 ? tags.join(', ').trim() : undefined,
    noIndex: hidden ? true : false,
  }

  return (
    <IndexLayout>
      <SEO {...seo} />
      <Page>
        <ProjectHeader
          title={titleHtml || title}
          overview={overview}
          tags={tags}
          role={role}
          liveUrl={liveUrl}
        />
        <Container maxWidth="xxxl">
          <MDXRenderer>{body}</MDXRenderer>
        </Container>
        <Container>
          <PostFooter previous={previous} next={next} />
        </Container>
      </Page>
    </IndexLayout>
  )
}

export default ProjectTemplate

export const query = graphql`
  query ProjectTemplateQuery($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      slug
      frontmatter {
        title
        titleHtml
        thumbnail
        overview
        role
        tags
        liveUrl
        hidden
        cover {
          publicURL
        }
      }
    }
  }
`
